import { useState } from "react";
import { RefsContext } from "../contexts/RefsContext";

export const RefsProvider = ({ children }: { children: React.ReactNode }) => {
	const [refs, setRefs] = useState<Record<string, React.RefObject<HTMLDivElement>>>({});

	return (
		<RefsContext.Provider value={{ refs, setRefs }}>
			{children}
		</RefsContext.Provider>
	)
}