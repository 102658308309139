import { Box, Typography, styled } from "@mui/material";
import { MainStyledBox } from "./styled/Box";
import { ContentContainer } from "./styled/Containers";
import { forwardRef } from "react";

const TokenomicsBlockContainer = styled(MainStyledBox)`
	width: 100%;
	background: linear-gradient(92deg, ${({ theme }) => theme.palette.primary.main} -0.81%, ${({ theme }) => theme.palette.secondary.main} 34.81%, ${({ theme }) => theme.palette.info.main} 96.9%);
	min-height: 350px;
`;

const StyledContentContainer = styled(ContentContainer)`
	margin: 3rem;
	justify-content: space-around;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		gap: 2rem;
	}
`;

const Title = styled(Typography)`
	margin-top: 2rem;
	color: ${({ theme }) => theme.palette.background.default};
`;

const ContentItem = styled(Box)`
	text-align: center;
`;

const Percentage = styled(Typography)`
	font-size: 4rem;
	color: ${({ theme }) => theme.palette.background.default};
`;
const Description = styled(Typography)`
	font-size: 1.5rem;
	color: ${({ theme }) => theme.palette.background.default};
`;

export const TokenomicsBlock = forwardRef((_, ref) => {
	return (
		<TokenomicsBlockContainer ref={ref}>
			<Title variant="h4">TOKENOMICS</Title>
			<StyledContentContainer>
				<ContentItem>
					<Percentage variant="h2">5/5%</Percentage>
					<Description>Tax</Description>
				</ContentItem>
				<ContentItem>
					<Percentage variant="h2">95%</Percentage>
					<Description>Liqudity</Description>
				</ContentItem>
				<ContentItem>
					<Percentage variant="h2">5%</Percentage>
					<Description>Team</Description>
				</ContentItem>
			</StyledContentContainer>
		</TokenomicsBlockContainer>
	);
});