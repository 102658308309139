import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import createTheme from '@mui/material/styles/createTheme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const defaultTypography = {
  fontFamily: [
    'RussoOne',
  ].join(','),
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#FF6913' },
    secondary: { main: '#FFA51E' },
    info: { main: '#811DBC' },
    background: {
      default: '#101010',
      paper: '#191A1C'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#BCBCBC'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: '#212B36'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'RussoOne',
          borderRadius: '4rem',
          fontWeight: 700,
          textTransform: 'none',
          padding: '0.5rem 2rem',
        },
        text: {
          fontFamily: 'CodecPro',
          fontWeight: 400,
        }
      }
    }
  },
  typography: {
    h1: defaultTypography,
    h2: defaultTypography,
    h3: defaultTypography,
    h4: defaultTypography,
    h5: defaultTypography,
    h6: defaultTypography,
    fontFamily: [
      'CodecPro',
    ].join(',')
  }
});

const theme = responsiveFontSizes(darkTheme);

root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StyledEngineProvider>
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
