import { forwardRef, useRef } from "react";
import { createPortal } from "react-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { StyledItem } from "./styled/Item";
import { PrimaryText, SecondaryText } from "./styled/Titles";
import { ItemWithBottomHighlight } from "./ItemWithBottomHighlight";
import { alpha } from "@mui/material/styles";
import CarouselItemBackground from "../images/carousel_item.png";
import { BrightnessBackground } from "./styled/BrightnessBackground";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
		slidesToSlide: 3,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	}
};

const CarouselWrapper = styled(StyledItem)`
	width: 100%;
	box-sizing: border-box;
	margin: auto;
	background: transparent;
	
	padding: 2rem 0;
`;

const CarouselTopSection = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;

	width: calc(100% - 1rem);

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
		flex-wrap: wrap;
    justify-content: center;
		gap: 1rem;
	}
`;

const ActionsContainer = styled(Box)`
	display: flex;
	gap: 0.5rem;
`;

const ActionButton = styled(Button)`
	border-radius: 100%;
	width: 2rem;
	min-width: 2rem;
	height: 2rem;
	font-weight: 300;
	background-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.25)};
	color: ${({ theme }) => theme.palette.primary.main};

	padding: 0;

	&:active, &:focus-within {
		background-color: ${({ theme }) => theme.palette.primary.main};
		color: ${({ theme }) => theme.palette.background.default};
	}
`;

const BlockWithImage = styled(Box)`
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
	
	position: relative;
	overflow: hidden;
	background: url(${CarouselItemBackground});
	border-radius: 1rem;
	min-height: 7.5rem;
`;

const StyledPrimaryText = styled(PrimaryText)`
	position: relative;
	z-index: 1;
	margin: 0 0 1rem 0;
`;

const StyledPhaseText = styled(Typography)`
	position: relative;
	z-index: 1;
	padding: 0.25rem 1rem;
	margin: 1rem 0;
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledDescription = styled(SecondaryText)`
	padding: 0.5rem;
`;

type CarouselItemData = {
	title: string,
	description?: string[],
	num?: number
}

type CarouselProps = { title: string, items: CarouselItemData[] };

type CarouselItemProps = CarouselItemData

const CarouselItem = ({ title, description, num }: CarouselItemProps) => {

	return (
		<div style={{ display: 'flex', height: '100%' }} >
			<ItemWithBottomHighlight>
				<BlockWithImage>
					<BrightnessBackground />
					<StyledPhaseText variant="subtitle2">Phase {num && num >= 10 ? num : `0${num}`}</StyledPhaseText>
					<StyledPrimaryText variant="h6">{title}</StyledPrimaryText>
				</BlockWithImage>
				<StyledDescription variant="body2">
					<ul>
						{description?.map((item, index) => (
							<li style={{ marginBottom: '0.25rem' }} key={index}>{item}</li>
						))}
					</ul>
				</StyledDescription>
			</ItemWithBottomHighlight>
		</div>
	)
}

const CustomButtonGroupAsArrows = (props: any) => {
	const nextDisabled = props.carouselState.currentSlide === props.carouselState.totalItems - props.carouselState.slidesToShow;
	const prevDisabled = !props.carouselState.currentSlide;

	return props.portalNode.current
		&& createPortal(
			<ActionsContainer>
				<ActionButton variant="contained" disabled={prevDisabled} onClick={props.previous}>
					&lt;
				</ActionButton>
				<ActionButton variant="contained" disabled={nextDisabled} onClick={props.next}>
					&gt;
				</ActionButton>
			</ActionsContainer>, props.portalNode.current
		)
};

export const CarouselComponent = forwardRef(
	(
		{ title, items }: CarouselProps,
		ref: React.Ref<HTMLDivElement>
	) => {
		const carouselTopSectionRef = useRef<HTMLDivElement>(null);


		return (
			<CarouselWrapper elevation={0} ref={ref}>
				<CarouselTopSection ref={carouselTopSectionRef}>
					<PrimaryText variant="h3">{title}</PrimaryText>
				</CarouselTopSection>
				<Carousel
					responsive={responsive}
					arrows={false}
					renderButtonGroupOutside
					swipeable
					draggable
					customButtonGroup={<CustomButtonGroupAsArrows portalNode={carouselTopSectionRef} />}
				>
					{
						items.map((item, index) => (
							<CarouselItem
								key={index}
								num={index + 1}
								{...item}
							/>
						))
					}
				</Carousel>
			</CarouselWrapper>
		);
	});