import styled from "@mui/material/styles/styled";
import { ContentContainer } from "./styled/Containers"
import { PrimaryText } from "./styled/Titles"
import Box from "@mui/material/Box";
import SpotifyImg from "../images/spotify.svg";
import CoinbaseImg from "../images/coinbase.svg";
import SlackImg from "../images/slack.svg";
import DropboxImg from "../images/dropbox.svg";
import ZoomImg from "../images/zoom.svg";
import { StyledItem } from "./styled/Item";
import { darken } from "@mui/material";
import { forwardRef } from "react";

const PartnershipWrapper = styled(Box)`
	width: 100%;
	background-color: ${({ theme }) => darken(theme.palette.background.default, 0.2)};
`;

const PartnershipContainer = styled(ContentContainer)`
	padding: 2rem 0;
	justify-content: center;
	align-items: center;
	gap: 2rem;
`;

const PartnersContainer = styled(Box)`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const PartnerBlock = styled(StyledItem)`
	display: flex;
	justify-content: center;

	width: 130px;
	min-width: 130px;
	max-width: 130px;
	border-radius: 1rem;
	padding: 2rem;
`;

const StyledImg = styled('img')`
	height: 40px;
`;

export const PartnershipBlock = forwardRef((_, ref) => {
	return (
		<PartnershipWrapper>
			<PartnershipContainer ref={ref}>
				<PrimaryText variant="h3">Partnership</PrimaryText>
				<PartnersContainer>
					<PartnerBlock elevation={0}>
						<StyledImg src={SpotifyImg} alt="spotify" />
					</PartnerBlock>
					<PartnerBlock elevation={0}>
						<StyledImg src={CoinbaseImg} alt="coinbase" />
					</PartnerBlock>
					<PartnerBlock elevation={0}>
						<StyledImg src={SlackImg} alt="slack" />
					</PartnerBlock>
					<PartnerBlock elevation={0}>
						<StyledImg src={DropboxImg} alt="dropbox" />
					</PartnerBlock>
					<PartnerBlock elevation={0}>
						<StyledImg src={ZoomImg} alt="zoom" />
					</PartnerBlock>
				</PartnersContainer>
			</PartnershipContainer>
		</PartnershipWrapper>
	)
});