
import { forwardRef } from "react";
import { CarouselComponent } from "./Carousel";
import { ContentContainer } from "./styled/Containers";

const CAROUSEL_PARAMS = {
	title: 'Roadmap',
	items: [
		{
			title: 'Launch',
			description: ['- Launching website and social media accounts.', '- Market Watch Bot Release.', '- $MBOT token Launch.', '- Whitepaper V1 .']
		},
		{
			title: 'Expansion',
			description: ['- Conducting a contract audit', '- Releasing a premium subscription.', '- Multi Chain support.', '- Establishing partnerships.']
		},
		{
			title: 'Consolidation',
			description: ['- Implementing revenue sharing mechanisms.', '- implementation of machine learning analysis capabilities.', '- Listing our project on the first CEX.', '- Releasing Whitepaper V2.']
		},
	]
}

export const RoadmapBlock = forwardRef((_, ref) => {
	return (
		<ContentContainer ref={ref}>
			<CarouselComponent {...CAROUSEL_PARAMS} />
		</ContentContainer>
	);
})