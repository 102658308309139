import { styled } from "@mui/material";
import Box from "@mui/material/Box";

export const StyledBox = styled(Box)`
 	display: flex;
	justify-content: flex-end; 
	align-items: center;
	padding: 1rem 2rem;
	z-index: 2;
`;

export const MainStyledBox = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
`;
