import styled from "@mui/material/styles/styled";
import { MainStyledBox } from "./styled/Box";
import { Actions } from "./Actions";
import { Links } from "./Links";
import { Header } from "./Header";
import Box from "@mui/material/Box";
import AboutUsBackground from "../images/about_us_background.png";
import LogoBig from "../images/logo_big.svg";
import { PrimaryText, PrimaryTitle, SecondaryTitle, TitleOutlined } from "./styled/Titles";
import { ContentContainer } from "./styled/Containers";
import { ItemWithBottomHighlight } from "./ItemWithBottomHighlight";
import { BrightnessBackground } from "./styled/BrightnessBackground";
import { Typography } from "@mui/material";
import { forwardRef } from "react";

const MainContainer = styled(MainStyledBox)`
	margin: 0 0 3rem 0;
	align-items: center;
	width: 100%;

	border-radius: 1rem;
	overflow: hidden;

	& > img {
		width: 50%;
		min-width: 350px;
		z-index: 1;
	}
`;

const BoxWithBackground = styled(Box)`
	display: flex;
	background-image: url(${AboutUsBackground});
	background-repeat: no-repeat;
	width: calc(100% - 2rem);
	position: relative;
  border-radius: 2rem;
  padding: 3rem 1rem;
	overflow: hidden;

	& > img {
		height: 50vh;
		min-height: 350px;
		z-index: 1;
	}
`;

const TextBox = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 2rem 0;
`;

const StyledLogo = styled('img')`
	height: 60vh;
	padding: 1rem 0;

	${({ theme }) => theme.breakpoints.down('md')} {
		height: 40vh;
	}
`;

const StyledContentContainer = styled(ContentContainer)`
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 2rem 0;
`;

const FirstTitle = styled(Typography)`
	color: #101010;
	font-variant-numeric: lining-nums proportional-nums;
	font-family: 'RussoOne';
	background: linear-gradient(96deg, #FFC83D 4.97%, #FF6913 49.68%, #8624B5 96.05%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-shadow: 0 0 1px ${({ theme }) => theme.palette.background.default};
`;

const SecondTitle = styled(SecondaryTitle)`
	color: ${({ theme }) => theme.palette.secondary.main};
	background: linear-gradient(96deg, #FFC83D 4.97%, #FF6913 49.68%, #8624B5 96.05%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

const TEXTS = {
	title: "Welcome to",
	secondaryTitle: "Marketwatch BOT",
}
export const AboutUsBlock = forwardRef((_, ref) => {
	return (
		<>
			<MainContainer>
				<Header />
				<BoxWithBackground>
					<BrightnessBackground />
					<ContentContainer>
						<TextBox>
							<FirstTitle variant="h3">{TEXTS.title}</FirstTitle>
							<SecondTitle variant="h2">{TEXTS.secondaryTitle}</SecondTitle>
							<Actions />
							<Links />
							<SecondTitle variant="h6" style={{ alignSelf: "flex-end", wordBreak: 'break-word', marginTop: 'auto' }}>
								Contract: 0xb1cbf92d5900402dad0614b87a918122605ebbab
								</SecondTitle>
						</TextBox>
						<StyledLogo src={LogoBig} alt="logo" />
					</ContentContainer>
				</BoxWithBackground>
			</MainContainer>
			<ContentContainer>
				<ItemWithBottomHighlight>
					<StyledContentContainer ref={ref}>
						<PrimaryText variant="h4">
							ABOUT
						</PrimaryText>
						<PrimaryTitle
							variant="h4"
						>
							MARKETWATCH BOT
						</PrimaryTitle>
					</StyledContentContainer>
					<PrimaryText variant="body1" sx={{ padding: "0 1rem 2rem 1rem" }}>
					Market Watch BOT is a unique analytical tool that helps traders find the most profitable wallets for any token. It functions as a Telegram-based wallet analyzer bot, allowing users to discover addresses that have achieved significant profits through token trading. The free version permits up to 5 contract address checks per day. However, premium users enjoy unlimited checks and extra features such as wallet tracking and prompt alerts. Access to these advanced features requires the $MBOT token.
					</PrimaryText>
				</ItemWithBottomHighlight>
			</ContentContainer>
		</>
	);
});