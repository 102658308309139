import styled from "@mui/material/styles/styled";
import { StyledBox } from "./styled/Box";
import SmallLogo from "../images/logo_small.svg"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRefs } from "../hooks/useRefs";
import { ContentContainer } from "./styled/Containers";


const HeaderContainer = styled(StyledBox)`
	width: 100%;
	padding: 1rem 0;
	flex-wrap: wrap;
	justify-content: center;
	gap: 2rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		justify-content: center;
	}

	img {
		height: 40px;
	}
`;

const LinksSection = styled(Box)`
	display:flex;
	flex-wrap: wrap;
	gap: 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		padding: 2rem 0;
	}
`;

const AnchorButton = styled(Button) <{ disabled?: boolean }>`
	padding: 0.5rem 1rem;
	color: ${({ theme }) => theme.palette.text.secondary};

	&.Mui-disabled {
		color: ${({ theme }) => theme.palette.primary.main};
	}

	&:hover {
		color: ${({ theme }) => theme.palette.primary.main};
	}
`;

const StyledJoinButton = styled(Button)<{ component: any; target: any;}>`
	background-color: ${({ theme }) => theme.palette.background.paper};
	color: ${({ theme }) => theme.palette.secondary.main};

	&:hover {
		background-color: ${({ theme }) => theme.palette.secondary.main};
		color: ${({ theme }) => theme.palette.background.paper};
	}
`;

export const Header = () => {
	const { refs } = useRefs();
	const onClick = (anchor: string) => refs[anchor]?.current?.scrollIntoView({ behavior: 'smooth' });

	return (
		<HeaderContainer>
			<ContentContainer>
				<img src={SmallLogo} alt="logo" />
				<LinksSection>
					<AnchorButton color="primary" variant="text" onClick={() => onClick('about')}>About</AnchorButton>
					<AnchorButton color="primary" variant="text" onClick={() => onClick('features')}>Features</AnchorButton>
					<AnchorButton color="primary" variant="text" onClick={() => onClick('tokenomics')}>Tokenomics</AnchorButton>
					<AnchorButton color="primary" variant="text" onClick={() => onClick('roadmap')}>Roadmap</AnchorButton>
				</LinksSection>
				<StyledJoinButton component="a" target="_blank" href="https://t.me/MarketWatchBot_ERC" rel="noreferrer" color="primary" variant="contained">Join</StyledJoinButton>
			</ContentContainer>
		</HeaderContainer>
	)
}