import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import SmallLogo from "../images/logo_small.svg";
import { Links } from "./Links";
import Button from "@mui/material/Button";
import { useRefs } from "../hooks/useRefs";
import { ContentContainer } from "./styled/Containers";
import { PrimaryText } from "./styled/Titles";
import { Link } from "@mui/material";

const FooterContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 1rem 2rem;
	min-height: 75px;
	position: relative;
	z-index: 2;
	background: #371D0F;

	${({ theme }) => theme.breakpoints.down('md')} {
		justify-content: center;
		gap: 2rem;
	}
`;

const ActionsContainer = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	gap: 1rem;

	& .MuiBox-root {
		padding: 0;
		margin: auto;
		width: unset;
	}
`;

const LogoAndEmailContainer = styled(Box)`
	display: flex;
	flex-flow: column wrap;
	gap: 1rem;
`;

const StyledButton = styled(Button) <{ component: any; target: any; }>`
	min-width: 150px;
`;

export const Footer = () => {
	const { refs } = useRefs();
	const onClick = () => refs['applyForAirdrop']?.current?.scrollIntoView({ behavior: 'smooth' });

	return (
		<FooterContainer>
			<ContentContainer>
				<LogoAndEmailContainer>
					<img src={SmallLogo} alt="logo" style={{ height: "3rem" }} />
					<PrimaryText variant="body1">
						Email: <Link href="mailto:neemeyraymih@hotmail.com">neemeyraymih@hotmail.com</Link>
					</PrimaryText>
				</LogoAndEmailContainer>
				<ActionsContainer>
					<StyledButton rel="noreferrer" component="a" target="_blank" href="https://t.me/MarketWatchBot_ERC" variant="contained" onClick={onClick}>
						BUY
					</StyledButton>
					<Links />
				</ActionsContainer>
			</ContentContainer>
		</FooterContainer>
	)
}