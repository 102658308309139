import styled from "@mui/material/styles/styled";
import { ContentContainer } from "./styled/Containers";
import Box from "@mui/material/Box";
import LogoBig from "../images/logo_big.svg";
import { StyledItem } from "./styled/Item";
import { PrimaryText } from "./styled/Titles";
import { CheckIcon } from "./icons/check";
import { forwardRef } from "react";

const TEXTS = {
	title: "FEATURES",
}

const FeaturesBlockContainer = styled(StyledItem)`
	border-radius: 0;
	
	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

const TextBox = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-basis: 50%;
	margin: 2rem 0;
`;

const StyledLogo = styled('img')`
	height: 60vh;
	padding: 1rem 0;

	${({ theme }) => theme.breakpoints.down('md')} {
		height: 40vh;
	}
`;

const CircleBackground = styled(Box)`
	display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 30rem;
	margin: 2rem 0;
	
	border-radius: 100%;
	background: radial-gradient(85.49% 86.68% at 36.27% 16.11%, #FFA11D 0%, #FF6913 38.02%, #8420B8 100%);

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 20rem;
		height: 20rem;
	}
`;

const StyledPrimaryText = styled(PrimaryText)`
	display: flex;
	gap: 1rem;

	margin: 0.5rem 0;
`;

const StyledContentContainer = styled(ContentContainer)`
	align-items: center;
`;

const LIST = [
	'Find the smartest traders of DeFi in real time.',
	'Analyze any CA to find the best traders of the token.',
	'Follow wallet transactions and swaps.',
	'Revenue share for $MBOT holders.'
];

const FeaturesList = () => {
	return (
		<>{LIST.map((item, index) => (
			<StyledPrimaryText
				key={index}
				variant="body2"
			>
				<CheckIcon />
				{item}
			</StyledPrimaryText>
		))
		}</>
	)
}

export const FeaturesBlock = forwardRef((_, ref) => {
	return (
		<FeaturesBlockContainer elevation={0}>
			<StyledContentContainer ref={ref}>
				<TextBox>
					<StyledPrimaryText variant="h4" sx={{ marginY: '2rem'}}>{TEXTS.title}</StyledPrimaryText>
					<FeaturesList />
				</TextBox>
				<CircleBackground>
					<StyledLogo src={LogoBig} alt="logo" />
				</CircleBackground>
			</StyledContentContainer>
		</FeaturesBlockContainer>
	)
});