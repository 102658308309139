import { Route, Routes } from "react-router-dom";

import { MainPage } from "./pages/Main";
import { Layout } from "./components/Layout";

const App = () => {  
  return (
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<MainPage />} />
            <Route path="*" element={<MainPage />} />
          </Route>
        </Routes>
  )
}

export default App;
