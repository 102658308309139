import styled from "@mui/material/styles/styled";
import { StyledBox } from "./Box";

export const ActionsBox = styled(StyledBox)`
	padding: 1rem 0;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;

	gap: 1rem;
`;

export const StyledActionBox = styled(ActionsBox)`
	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		align-items: center;
	}
`;