import styled from "@mui/material/styles/styled";
import { ActionsBox } from "./styled/ActionsBox";
import { Button } from "@mui/material";

const LinksContainer = styled(ActionsBox)`
	padding: 0.5rem 0rem;
`;

const StyledButton = styled(Button)<{ component?: any, target?: any, rel?: any }>`
	min-width: 150px;
	font-size: 1rem;
	font-family: 'CodecPro';
	
	background-color: ${({ theme }) => theme.palette.background.paper};
	color: ${({ theme }) => theme.palette.primary.main};

	&:hover {
		background-color: ${({ theme }) => theme.palette.primary.main};
		color: ${({ theme }) => theme.palette.background.paper};
	}
`;

export const Links = () => {
	return (
		<LinksContainer>
			<StyledButton 
				component="a"
				href="https://t.me/MarketWatchBot_ERC"
				target="_blank"
				rel="noreferrer"
				variant="contained"
			>
				Telegram
			</StyledButton>
			<StyledButton
				component="a"
				href="https://twitter.com/Marketwatch_bot"
				target="_blank"
				rel="noreferrer"
				variant="contained"
			>
				Twitter
			</StyledButton>
		</LinksContainer>
	)
};