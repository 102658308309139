import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import styled from '@mui/material/styles/styled';

export const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: '0.5rem 1rem',
	margin: '0 2rem',
	textAlign: 'center',
	color: theme.palette.text.secondary,
	borderRadius: '2rem',
	height: 'calc(100% - 1rem)',
}));

export const StyledItem = styled(Item)`
	width: calc(100% - 2rem);
	margin: 0;
	text-align: left;
`;

export const StyledItemWithBottomHighlight = styled(Box)`
	width: calc(100% - 2rem);
	position: relative;

	margin: 0 auto 4rem;
`;