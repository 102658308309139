import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled"

const Background = styled(Box)`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: ${({ theme }) => theme.palette.background.default};  /* fallback for old browsers */
`;

export const Layout = () => {
	return (
		<Background>
			<Outlet />
		</Background>
	)
}