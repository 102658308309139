import Button from "@mui/material/Button";
import { ActionsBox } from "./styled/ActionsBox";
import styled from "@mui/material/styles/styled";
import { darken } from "@mui/material";

const StyledButton = styled(Button)<{ component: any; target: any;}>`
	min-width: 50px;
	font-size: 1rem;
	color: ${({ theme }) => theme.palette.background.default};
`;

const StyledButtonWithGradient = styled(StyledButton)<{ component: any; target: any;}>`
	background: linear-gradient(92deg, ${({ theme }) => theme.palette.primary.main} -0.81%, ${({ theme }) => theme.palette.secondary.main} 34.81%, ${({ theme }) => theme.palette.info.main} 96.9%);
	transition: all 0.5s;
	min-width: 150px;

	&:hover {
		background: linear-gradient(92deg, ${({ theme }) => darken(theme.palette.primary.main, 0.25)} -0.81%, ${({ theme }) => darken(theme.palette.secondary.main, 0.25)} 34.81%, ${({ theme }) => darken(theme.palette.info.main, 0.25)} 96.9%);
	}
`;

export const Actions: () => JSX.Element = () => {

	return (
		<ActionsBox>
			<StyledButtonWithGradient rel="noreferrer" component="a" target="_blank" href="https://t.me/MarketWatchBot_ERC" color="secondary" variant="contained">BUY</StyledButtonWithGradient>
			<StyledButton rel="noreferrer" component="a" target="_blank" href="https://t.me/MarketWatchBot_ERC" color="primary" variant="contained">CHART</StyledButton>
			<StyledButton rel="noreferrer" component="a" target="_blank" href="https://t.me/MarketWatchERC_Bot" color="info" variant="contained">BOT</StyledButton>
		</ActionsBox>
	);
}