import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import { MainStyledBox } from "./Box";

export const FullHeightBox = styled(MainStyledBox)`
	min-height: calc(100vh - 4rem);
	width: 100%;
	padding: 0;
`;

export const ContentContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 70%;
	margin: auto;
	z-index: 1;
	gap: 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 90%;
	}
`;