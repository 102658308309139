import { ReactNode } from "react";
import { StyledItem, StyledItemWithBottomHighlight } from "./styled/Item";
import styled from "@mui/material/styles/styled";

const ItemBlock = styled(StyledItem)`
	z-index: 1;
	padding: 1rem;
	box-sizing: border-box;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
	}
`;

const ItemHightlight = styled(StyledItem)`
	position: absolute;
	bottom: 0.75rem;
	border-radius: 2rem;
	background: linear-gradient(92deg, ${({ theme }) => theme.palette.primary.main} -0.81%, ${({ theme }) => theme.palette.secondary.main} 34.81%, ${({ theme }) => theme.palette.info.main} 96.9%);
	width: calc(100% - 6rem);
	height: 10px;
	left: 1rem;
	margin: auto;
	z-index: -1;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: calc(100% - 4rem);
		bottom: 0.75rem;
	}
`;


type StyledItemWithBottomHighlightProps = {
	children: ReactNode | string;
}

export const ItemWithBottomHighlight = ({ children }: StyledItemWithBottomHighlightProps) => (
	<StyledItemWithBottomHighlight>
		<ItemBlock elevation={0}>
			{children}
		</ItemBlock>
		<ItemHightlight elevation={0}>
		</ItemHightlight>
	</StyledItemWithBottomHighlight>
);