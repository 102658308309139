import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";

export const BrightnessBackground = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.8);
`;