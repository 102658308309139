import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

export const PrimaryTitle = styled(Typography)`
	color: ${({ theme }) => theme.palette.primary.main};
	padding: 0.5rem 0;
`;

export const SecondaryTitle = styled(Typography)`
	color: ${({ theme }) => theme.palette.secondary.main};
`;

export const TitleOutlined = styled(Typography)<{ color?: string, shadowColor?: string }>`
	color: ${({ color, theme }) => color || theme.palette.background.default};
	text-shadow: 0 0 2px ${({ shadowColor, theme }) => shadowColor || theme.palette.text.primary};
	padding: 0.5rem 0;
`;

export const PrimaryDarkTitle = styled(Typography)`
	display: flex;
	gap: 1rem;

	padding: 0.25rem 0;
	color: ${({ theme }) => theme.palette.primary.dark};
`;

export const PrimaryText = styled(Typography)`
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const SecondaryText = styled(Typography)`
	color: ${({ theme }) => theme.palette.text.secondary};
`;