import { useEffect, useRef } from "react";
import { MainStyledBox } from '../components/styled/Box';
import { Footer } from "../components/Footer";
import { AboutUsBlock } from "../components/AboutUsBlock";
import { RefsProvider } from "../providers/refsProvider";
import { useRefs } from "../hooks/useRefs";
import styled from "@mui/material/styles/styled";
import { FullHeightBox } from "../components/styled/Containers";
import { TokenomicsBlock } from "../components/TokenomicsBlock";
import { FeaturesBlock } from "../components/FeaturesBlock";
import { RoadmapBlock } from "../components/RoadmapBlock";
import { PartnershipBlock } from "../components/PartnershipBlock";


const StyledFullHeightBox = styled(FullHeightBox)`
	min-height: calc(100vh - 4rem);
	justify-content: flex-start;
	padding: 0;
`;

const AboutUs = () => {
	const {setRefs} = useRefs();
	const aboutUsRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			about: aboutUsRef
		}));
	},
	[setRefs]);

	return (
		<StyledFullHeightBox>
			<AboutUsBlock ref={aboutUsRef} />
		</StyledFullHeightBox>
	);
}

const Roadmap = () => {
	const {setRefs} = useRefs();
	const roadmapRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			roadmap: roadmapRef
		}));
	},
	[setRefs]);

	return (
		<RoadmapBlock ref={roadmapRef} />
	)
};

const Features = () => {
	const {setRefs} = useRefs();
	const featuresRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			features: featuresRef
		}));
	},
	[setRefs]);

	return (<FeaturesBlock ref={featuresRef} />)
};

const Tokenomics = () => {
	const {setRefs} = useRefs();
	const tokenomicsRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			tokenomics: tokenomicsRef
		}));
	},
	[setRefs]);

	return (<TokenomicsBlock ref={tokenomicsRef} />)
}

const Partnership = () => {
	const {setRefs} = useRefs();
	const partnershipRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			partnership: partnershipRef
		}));
	},
	[setRefs]);

	return (<PartnershipBlock ref={partnershipRef} />)
}

export const MainPage = () => {
	return (
		<RefsProvider>
				<MainStyledBox>
					<AboutUs />
					<Features />
					<Tokenomics />
					<Roadmap />
				</MainStyledBox >
				<Footer />
		</RefsProvider>
	)
}